$primary-colour: #23b4be;
$secondary-colour: rgb(202, 131, 23);
$background-colour: #fffaf0;
$min-width: 992px;

:export {
    iconBackground: $primary-colour;
    iconForeground: white;
}

* {
    font-size: 100%;
    font-family: Calibri, sans-serif;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
    width: 0.75em;
}
 
::-webkit-scrollbar-track {
    display: none;
}
 
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $primary-colour;
}

h1 {
    text-align: center;
}

@media only screen and (min-width: $min-width) {
    h1 {
        font-size: 10em !important;
        text-align: center;
    } 
}

h2 {
    font-size: 4em;
    font-weight: bold;
    color: $primary-colour;
}

h3 {
    font-size: 2em;
    color: $secondary-colour;
    text-align: center;
    margin: 0 10px;
}

h4 {
    font-size: 2em;
    font-weight: bold;
    color: $secondary-colour;
}

h5 {
    font-size: 1.5em;
    font-weight: bold;
}

body {
    background-color: $background-colour;
}

ul {
    list-style-type: none;
    list-style-position: none;
    padding: 0;
}

span {
    vertical-align: middle;
}

.link {
    font-size: 20px !important;
    color: $secondary-colour;
    margin: 30px;
    text-align: center;
}

.link:hover {
    text-decoration: none;
    color: $primary-colour;
}

.link-footer {
    font-size: 20px;
    color: white;
    margin: 30px;
    text-align: center;
}

.link-footer:hover {
    text-decoration: none;
    color: white;
}

.title {
    font-size: 100px;
    color: white;
}

.title-text {
    padding: 20% 0;
}

.title-img {
    background-image: url(/images/beach.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    margin: 0;
}

@media only screen and (min-width: $min-width) {
    .title-img {
        background-attachment: fixed;
    }
}

.title-img-fade {
    opacity: 0;
}

.title-logo-fade {
    text-align: center;
    padding: 20% 0;
    display: none;
}

.title-logo {
    height: 50vw;
}

@media only screen and (min-width: $min-width) {
    .title-logo {
        height: 50vh;
    }
}

.title-foreground-fade {
    display: none;
}

.pulse-button {
    position: absolute;
    bottom: 0;
    border: 0;
    color: $primary-colour;
    background-color: white;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
}

.pulse-button:hover {
    color: white;
    background-color: $primary-colour;
    animation: none;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 white;
    }
    
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
    }
    
    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.sticky {
    position: sticky;
    top: 0;
    visibility: visible;
    z-index: 10;
    transition: all 0.3s ease-in-out;
}

.hidden {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.dropdownMenu {
    margin-top: 0px;
}

.dropdown-button-row {
    background-color: white !important; 
}

.dropdown-button-row:hover {
    background-color: $primary-colour#{'cc'} !important;
}

.dropdown-button {
    color: $primary-colour !important;
}

.dropdown-button:hover {
    color: white !important;
}

.category {
    padding-top: 100px;
}

.row-content {
    margin:0px auto;
    padding: 50px 0px 50px 0px;
    min-height:200px;
}

.profile {
    font-size: 1.5em;
    line-height: 200%;
}

.location {
    color: $primary-colour;
}

.icon-container {
    background-color: $primary-colour;
}

.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.timeline-summaries {
    font-size: 1.2em !important;
    margin: 1em 0;
}

.skill-category {
    padding: 1.5em 0;
}

.split-list-3 {
    column-count: 2;
    margin: 0 auto;
}

@media only screen and (min-width: $min-width) {
    .split-list-2 {
        column-count: 2;
    }
    .split-list-3 {
        column-count: 3; 
        margin: 0 auto;
    }
}

.split-list-item {
    font-size: 1.5em;
    margin: 0 0 10px 0;
    justify-content: space-between;
}

.hover-container {
    position: relative;
    margin: 10px;
}

.hover-image {
    opacity: 1;
    display: block;
    object-fit: cover;
    width: 300px;
    height: 300px;
    transition: .3s ease;
    backface-visibility: hidden;
}

.hover-text {
    position: absolute;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    color: $secondary-colour;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transition: .5s ease;
}

.hover-container:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5);
    transform: scale(1.01) !important;
}

.hover-container:hover .hover-image {
    opacity: 0.3;
}

.hover-container:hover .hover-text {
    opacity: 1;
}

.click {
    cursor: pointer;
}

.modal-title {
    font-size: 1.5em;
    color: $primary-colour;
    font-weight: bold;
}

.modal-button {
    font-size: 1.2em;
    color: white;
    background-color: $primary-colour;
    box-shadow: none;
}

.modal-button:hover {
    color: $primary-colour;
    background-color: white;
}

.modal-button:hover:active {
    color: $primary-colour !important; 
    background-color: white !important;
}

.tool-badge {
    font-size: 1.1em;
    display: inline;
}

.badge-colour {
    background-color: $secondary-colour;
}

.modal-description {
    font-size: 1.2em;
}

#navbar {
    text-align: center;
    background-color: $primary-colour#{'cc'};
    backdrop-filter: blur(10px) saturate(125%);
    -webkit-backdrop-filter: blur(10px);
}

.footer {
    background-image: url(/images/beach.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;
}

@media only screen and (min-width: $min-width) {
    .footer {
        background-attachment: fixed;
    }
}

.footer-header {
    padding-top: 100px;
    color: white;
}

hr.title-footer {
    border-color: white;
}